
  import { defineComponent, ref, watch } from 'vue';
  import { auditTags } from '@/states/tags'
  import { Tag } from '../../../../types/momai';
  export default defineComponent({
    name: 'UploadComment',
    props: {
      tipTag: {
        type: Boolean,
        default: false
      }
    },
    setup(props, {emit}) {
      const teaEva = ref('')
      const showTips = ref(false)
      const actives = ref<number[]>([])

      watch(teaEva, ()=> {
        emit('handleData', { type: 'text', uploadInfo: { info: teaEva.value, showOrHide: showTips.value}})
      })

      watch(showTips, () => {
        emit('handleData', { type: 'text', uploadInfo: { showOrHide: showTips.value}})
      })

      const showOrHide = () => {
        showTips.value = !showTips.value
      }

      const addTag = (t: Tag, idx: number) => {
        // teaEva.value += t.tagName
        // 判断是否包含在选中数组中
        if (!actives.value.includes(idx)) {
          actives.value.push(idx)
        } else {
          // 包含在数组中
          // 1. 找出下标
          const index = actives.value.indexOf(idx)
          // 2. 删除包含选中下标
          actives.value.splice(index, 1)
        }
        // 清空字符串
        teaEva.value = ''
        // 遍历选中下标，取值
        actives.value.map((item: number) => {
          const tag = auditTags.value[item]
          teaEva.value += tag.tagName + ','
        })
        // 去除最后一个逗号
        teaEva.value = teaEva.value.substr(0, teaEva.value.length - 1)
      }

      return {
        auditTags,
        teaEva,
        addTag,
        showOrHide,
        showTips,
        actives
      }
    }
  })
