
  import { defineComponent } from 'vue';
  import { Dialog } from 'vant';
  export default defineComponent({
    name: 'UploadAudio',
    props: {
      audioUrl: {
        type: String,
        required: true
      }
    },
    setup(props, {emit}) {
      const askDel = () => {
        Dialog.confirm({
          title: '确认',
          message: '确认删除录音吗？'
        }).then(() => {
          emit('handleData', { type: 'audio', uploadInfo: { delAudio: true }})
        }).catch(() => {})
      }
      return {
        askDel
      }
    }
  })
