
  import { defineComponent } from 'vue';
  import { Dialog } from 'vant';

  export default  defineComponent({
    name: 'UploadVideo',
    props: {
      videoUrl: {
        type: String,
        required: true
      },
      coverUrl: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const delVedio = () => {
        Dialog.confirm({
          title: '确认',
          message: '是否需要删除视频，重新上传？'
        }).then(() => {
          emit('handleData', { type: 'video', uploadInfo: { delVedio: true } })
        })
      }
      return {
        delVedio
      }
    }
  })
