
  import { defineComponent, PropType } from 'vue';
  import { Dialog, ImagePreview } from 'vant';
  export default defineComponent({
    name: 'UploadImg',
    props: {
      imgs: {
        type: Array as PropType<string[]>,
        required: true
      }
    },
    setup(props, {emit}){

      const preview = (index: number) => {
        ImagePreview({
          images: props.imgs,
          startPosition: index,
          showIndicators: true,
        });
      }

      const delImg = (index: number) => {
        Dialog.confirm({
          title: '确认',
          message: '是否需要删除该图片吗？'
        }).then(() => {
          emit('handleData', { type: 'img', uploadInfo: { delIndex: index } })
        })
      }

      return {
        preview,
        delImg
      }
    }
  })
